import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useForm } from 'react-hook-form';
import { useSubscribe } from '@wbk/api/hooks';
import { WithGrecaptcha } from '@wbk/api/grecaptcha';
import { Button, Input } from '@wbk/ui';
import { EMAIL_PATTERN } from '@wbk/utils';
import Reveal from '../animation/Reveal';

const NewsLetterSection = () => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{ email: string }>();
  const { mutateAsync, isPending } = useSubscribe();

  const onSubmit = async (data: { email: string }) => {
    try {
      await mutateAsync({
        email: data.email,
        type: 'organization',
        slug: process.env.NEXT_PUBLIC_ORGANIZATION_SLUG!,
        interests: [process.env.NEXT_PUBLIC_ORGANIZATION_SLUG!],
      });
      setSuccess(true);
    } catch (error) {
      //
    }
  };

  return (
    <WithGrecaptcha>
      <Reveal as='section' direction='bottom' className='container p-4 pb-12 text-text-dark'>
        <div className='relative flex flex-col-reverse overflow-hidden rounded-lg bg-gray-100 md:flex-row'>
          <div className='z-10 grow p-4 md:px-6'>
            <p className='text-2xl font-bold uppercase'>{t('common:newsletter.title')}</p>
            <p className='text-lg'>{t('common:newsletter.description')}</p>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className='mt-4 flex flex-col items-start gap-1 sm:flex-row'
            >
              <fieldset className='w-full grow md:max-w-md'>
                <Input
                  className='h-10 border-secondary bg-transparent text-secondary placeholder:text-secondary'
                  type='email'
                  placeholder={t('common:newsletter.email_placeholder')}
                  {...register('email', {
                    required: t('common:validation.required'),
                    pattern: {
                      value: EMAIL_PATTERN,
                      message: t('common:validation.invalid_email'),
                    },
                  })}
                  onChange={(value) => {
                    setValue('email', value);
                  }}
                  error={errors.email?.message}
                  disabled={success}
                  hideErrorSpace
                />
                {success && (
                  <p className='pb-2 text-sm text-success'>{t('common:newsletter.success')}</p>
                )}
              </fieldset>

              <Button
                type='submit'
                className='h-10 w-full px-6 ring-offset-gray-100 sm:w-auto'
                loading={isPending}
                disabled={success}
              >
                {t('common:newsletter.subscribe')}
              </Button>
            </form>
          </div>
          <div className='relative hidden w-24 -rotate-[25deg] scale-[2] bg-primary-dark md:block lg:w-12 ltr:left-6 rtl:right-6 rtl:rotate-[25deg]'></div>
          <div className='hidden grow items-center justify-center bg-primary-dark px-4 py-10 md:flex md:py-4'>
            <div className='relative h-16 w-60 rtl:w-48'>
              <Image
                className='rtl:hidden'
                src='/icons/logo-dark-tight-en.svg'
                fill
                sizes='200px'
                alt=''
              />
              <Image
                className='ltr:hidden'
                src='/icons/logo-dark-tight-ar.svg'
                fill
                sizes='200px'
                alt=''
              />
            </div>
          </div>
        </div>
      </Reveal>
    </WithGrecaptcha>
  );
};

export default NewsLetterSection;
