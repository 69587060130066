import { Button } from '@wbk/ui';
import { useTranslation } from 'next-i18next';
import { scrollIntoElementId } from '@wbk/utils';
import Image from 'next/image';

type Props = {
  showButton?: boolean;
};

const Hero = ({ showButton }: Props) => {
  const { t } = useTranslation();

  return (
    <section className='relative h-screen max-h-[530px]'>
      <Image
        className='inset-0 h-full w-full bg-secondary-dark object-cover object-bottom'
        fill
        src='/images/hero-image.png'
        alt=''
      />
      <span className='absolute inset-0 block h-full w-full from-body opacity-70 ltr:bg-gradient-to-r rtl:bg-gradient-to-l' />

      <div className='container relative flex h-full items-center'>
        <div className='space-y-6'>
          <h1 className='text-2xl font-normal uppercase md:text-4xl'>{t('home:alawwal')}</h1>
          <h2 className='max-w-2xl text-4xl uppercase md:text-6xl'>{t('home:desc')}</h2>
          {showButton && (
            <Button
              data-location='header'
              className='w-full max-w-sm p-2 font-bold'
              shiny
              onClick={() => scrollIntoElementId('pass-section')}
            >
              <span className='capitalize'>{t('common:buy_your_pass')}</span>
            </Button>
          )}
        </div>
      </div>
    </section>
  );
};

export default Hero;
